<template>
  <!-- Table section -->
  <div>
    <v-snackbar
      v-model="snackbar.active"
      bottom
      color="yellow darken-2"
      :timeout="3000"
    >
      <v-icon light small class="mr-1">check_circle</v-icon>
      <b class="black--text">{{ snackbar.msg }}</b>
      <v-btn light text @click="snackbar.active = false">
        ปิด
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarFaild.active"
      bottom
      color="error"
      :timeout="3000"
    >
      <v-icon dark small class="mr-1">cancel</v-icon>
      <b>{{ snackbarFaild.msg }}</b>
      <v-btn dark text @click="snackbarFaild.active = false">
        ปิด
      </v-btn>
    </v-snackbar>

    <!-- bottom sheet component -->
    <OrderDetailSheet
      :id="orderIdSheet"
      @clear-id="orderIdSheet = ''"
    ></OrderDetailSheet>
    <!-- end bottom sheet component -->

    <v-row>
      <v-col
        >ที่เลือก :
        <v-badge
          offset-x="-1"
          offset-y="20"
          color="yellow accent-4"
          v-if="name.totalCount > 0"
        >
          <template v-slot:badge>
            <span class="black--text">{{ name.totalCount }}</span>
          </template>
          <v-chip>
            {{ name.name }}
          </v-chip>
        </v-badge>
        <v-chip v-else-if="name">
          {{ name.name }}
        </v-chip>
      </v-col>
    </v-row>

    <v-card>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="mDataArray"
        :options.sync="options"
        :loading="dataTableLoading"
        :server-items-length="totalItems"
        :footer-props="{ 'items-per-page-options': [30, 50, 100, 200] }"
        show-select
        disable-filtering
        disable-sort
        mobile-breakpoint="200"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white" height="80">
            <v-toolbar-title>ค้นหา</v-toolbar-title>
            <v-divider class="mx-4" inset vertical=""></v-divider>
            <v-text-field
              v-model.trim="search_order"
              name="search_order"
              label="พิมพ์ ชื่อ | เบอร์"
              id="search_order"
              append-icon="search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>

            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-menu subheader>
              <template v-slot:activator="{ on }">
                <v-btn class="mb-1" icon light v-on="on">
                  <v-icon class="ml-1">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-subheader>เมนู</v-subheader>
                 <v-list-item @click="printOrder">
                    <v-list-item-icon><v-icon>print</v-icon></v-list-item-icon>
                    <v-list-item-content>
                    <v-list-item-title>Print</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                 <v-list-item @click="exportFlash">
                    <v-list-item-icon><v-icon>mdi-file-excel</v-icon></v-list-item-icon>
                    <v-list-item-content>
                    <v-list-item-title>Export xlsx</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader>เปลี่ยน สถานะ</v-subheader>
                <v-list-item
                  v-for="(item, index) in itemsMenuAction"
                  :key="index"
                  @click="doActionMenu(item)"
                > 
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item, select, isSelected }">
          <tr>
            <td>
              <v-simple-checkbox
                @input="select"
                :value="isSelected"
                :ripple="false"
              ></v-simple-checkbox>
            </td>
            <td>{{ item.id }}</td>
            <td>
              <OrderStatus :status="item.status"></OrderStatus>
              <!-- <v-tooltip top v-if="item.remark_admin">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-icon class="mx-1" small color="amber darken-3" v-on="on"
                      >info</v-icon
                    ></span
                  >
                </template>
                <span>{{ item.remark_admin }}</span>
              </v-tooltip> -->
              <div class="my-td-width-status" v-if="item.tracking">
                <span class="caption">{{ item.tracking }}</span>
                <v-tooltip top close-delay="200" :key="item.id">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      color="grey"
                      text
                      x-small
                      @click.prevent="copyTracking(item.tracking)"
                      ><v-icon x-small>mdi-content-copy</v-icon></v-btn
                    >
                  </template>
                  <span>Copy!</span>
                </v-tooltip>
              </div>
            </td>

            <td class="caption">
              {{ item.branch }}
            </td>
            <td class="pt-2 pb-2">
              <v-chip
                label
                :dark="true"
                outlined
                color="blue darken-3"
                small
                v-if="item.tag"
              >
                {{ item.tag }}
              </v-chip>
              {{ item.name }}
              <div class="grey--text text--darken-3 caption my-td-width3">
                <v-icon color="blue lighten-2" size="15">mdi-phone</v-icon>
                {{ item.tel1 }}, {{ item.tel2 }}
              </div>
                <div v-if="item.remark_admin">
                <v-icon size="15" color="orange darken-2">error_outline</v-icon>
                <span class="caption grey--text text--darken-3 ml-1 yellow lighten-2">{{ item.remark_admin }}</span>
              </div>
              <div v-if="item.tags">
                <v-chip
                  small
                  v-for="(tag, tindex) in item.tags.item"
                  :key="tindex"
                  class="mr-1"
                  >{{ tag.name }}</v-chip
                >
              </div>
            </td>
            <td>
              <v-chip
                color="green darken-3"
                :dark="true"
                v-if="item.is_cod == paymentMethods[1].id"
                class="ma-2"
                small
                outlined
              >
                <b>COD{{ item.amount | currency(" ฿", 0) }}</b>
              </v-chip>
              <v-chip light v-else class="ma-2" outlined small>
                PAID {{ item.amount | currency("฿", 0) }}
              </v-chip>

              <div v-if="item.cod_status == 1" class="caption">
                <v-icon size="12" color="blue darken-1">check_circle</v-icon>
                paid
                {{ item.cod_paid | moment("YYYY-MM-DD") }}
              </div>
            </td>

            <td class="caption">
              <div class="my-td-width">
                <v-icon color="orange" size="18">mdi-update</v-icon
                >{{ item.createdAt | moment("DD-MM-YYYY HH:mm") }}
              </div>
              <div v-if="item.createdAt != item.updatedAt">
                <v-icon color="green" small>mdi-update</v-icon
                >{{ item.updatedAt | moment("DD-MM-YYYY HH:mm") }}
              </div>
            </td>
            <td>
              <v-icon size="18" class="mr-1" @click="orderIdSheet = item.id"
                >airplay</v-icon
              >
              <v-icon size="18" class="mr-2" @click="editItem(item)"
                >search</v-icon
              >
              <v-icon size="18" class="mr-1" @click="deleteItem(item)"
                >delete</v-icon
              >
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="confirmDeleteDlg" max-width="290">
      <v-card>
        <v-card-title class="headline">Confirm Delete</v-card-title>
        <v-card-text class="body">
          ต้องการลบ <b>ออเดอร์</b> นี้ใช่หรือไม่?
          <h3>{{ selectedItem }}</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDeleteDlg = false"> cancel </v-btn>
          <v-btn color="error" text @click="confirmDelete">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmChangeStatusDlg" max-width="290">
      <v-card>
        <v-card-title class="headline">เปลี่ยนสถานะ</v-card-title>
        <v-card-text class="body">
          ต้องการเปลี่ยนสถานะเป็น
          <h3>
            <span class="blue--text">{{ selectedStatusAction.title }}</span>
            ใช่หรือไม่?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmChangeStatusDlg = false"> cancel </v-btn>
          <v-btn color="error" text @click="doChangeStatus">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api, {
  PAYMENTMETHOD,
  ORDERSTATUS,
  ORDERID_PREFIX,
} from "@/services/api";
import _ from "lodash";

import OrderStatus from "@/components/helpers/OrderStatus";
import OrderDetailSheet from "@/components/OrderDetailSheet";
import Exceljs from "exceljs";
import { saveAs } from "file-saver";

export default {
  name: "TagReportCard",
  props: ["tagId", "name"],
  components: {
    OrderDetailSheet,
    OrderStatus,
  },
  mounted() {
    //console.log('branch_id', this.branch_id);
    //console.log("duration", this.durations)
  },
  data() {
    return {
      selected: [],
      search_order: "",
      selectedItem: "",
      selectedItemId: "",
      confirmDeleteDlg: false, // false เท่ากับไม่ให้แสดง
      orderIdSheet: "",
      dataTableLoading: false,
      totalItems: 0,
      options: {}, // for dataTable
      mDataArray: [],
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "id",
          width: 80,
        },
        { text: "Status", value: "status", width: 140 },
        { text: "Branch", value: "branch", width: 120 },
        { text: "Name", value: "name" },
        { text: "Amount", value: "Amount", width: 150 },
        { text: "เข้าระบบ / อัพเดต", value: "date" },
        { text: "Action", value: "action" },
      ],
      snackbar: {
        active: false,
        msg: "",
      },
      snackbarFaild: {
        active: false,
        msg: "",
      },
      paymentMethods: [
        { id: PAYMENTMETHOD.TRANFER, name: "โอนเงิน" },
        { id: PAYMENTMETHOD.COD, name: "COD จ่ายปลายทาง" },
      ],
      itemsMenuAction: [
        { title: "รอ", actionType: ORDERSTATUS.WAIT },
        { title: "รอจัดการ", actionType: ORDERSTATUS.PENDING },
        { title: "PRINTED", actionType: ORDERSTATUS.PRINTED },
        { title: "KA-Exported", actionType: ORDERSTATUS.KAEXPORTED },
        { title: "KA-Booking", actionType: ORDERSTATUS.BOOKING },
        { title: "KA-PRINTED", actionType: ORDERSTATUS.KAPRINTED },
        { title: "สำเร็จ", actionType: ORDERSTATUS.COMPLETED },
        { title: "ยกเลิก", actionType: ORDERSTATUS.CANCELLED },
        { title: "Pre-Order สั่งจอง", actionType: ORDERSTATUS.PREORDER }
      ],
      confirmChangeStatusDlg: false,
      selectedStatusAction: "",
    };
  },
  computed: {},
  watch: {
    tagId: {
      handler(newVal, oldVal) {
        //this.getData();
        this.getData();
      },
      deep: true,
    },
    options: {
      //เมื่อค่า options ที่เรา sync กับ datable เปลี่ยน method นี้จะทำงานทันที  watch นี้ ชื่อ method ต้องตรงกันกับตัวแปรที่ประกาศข้างบน
      handler(newValue, oldValue) {
        // console.log(this.options.page, this.options.itemsPerPage)
        if (this.tagId) {
          this.getData();
        }
      },
      deep: true,
    },
    search_order: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.searchItems();
        }
      },
    },
  },
  methods: {
    searchItems: _.debounce(function() {
      // _.debounce ใน lodash ต่างจาก settimeout อย่างไร
      // 1. debounce จะ return ค่าออกมาเป็น function  สว่น   settimeout จะ return เป็น id
      // 2. debounce ตัวอย่างการใช้เช่น เวลาพิมพ์ search ข้อมูล function ใน debounce จะทำงานก็ต่อเมื่อเราได้หยุดพิมพ์ในเวลา 1.5 วิ แต่ถ้าในเวลา 1.5 วิ มีการพิมพ์ต่อ debounce ก็จะนับถอยหลังใหม่
      //     ซึ่งจะทำให้ การยิง request ไปที่ server ลดจำนวนการยิงลงจนกว่าเราจะพิมพ์ข้อความที่ต้องการค้นหาเสร็จ และไม่มีการพิมพ์ต่อเนื่องใน 1.5 วินั้นเอง
      this.getData();
    }, 1000),
    async getData() {
      this.dataTableLoading = true;
      const { page, itemsPerPage } = this.options;
      try {
        let result = await api.getTagReportById(
          this.tagId,
          page,
          itemsPerPage,
          this.search_order
        );
        if (result.data.result == "ok") {
          let data = JSON.parse(result.data.message);
          this.mDataArray = data.rows;
          this.totalItems = data.count;
          data = null;
        } else {
          // token is expired
          this.mDataArray = [];
          this.totalItems = 0;
          this.$router.push("/logout");
        }
        result = null;
      } catch (error) {
        console.log("tag id error: not select tagid: ", error.message);
      }
      this.dataTableLoading = false;
    },
    resetData() {
      this.mDataArray = [];
    },

    editItem(item) {
      this.$router.push(`/billing-edit/${item.id}`);
    },
    deleteItem(item) {
      this.selectedItemId = item.id;
      this.selectedItem = `รหัส: ${item.id} ${item.name}`;
      this.confirmDeleteDlg = true;
    },
    async confirmDelete() {
      let result = await api.deleteOrder(this.selectedItemId);
      if (result.data.result == "ok") {
        this.snackbar = {
          active: true,
          msg: "ลบข้อมูลสำเร็จ",
        };
        this.$emit("reload_data", true);
      } else {
        this.snackbarFaild = {
          active: true,
          msg: "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง",
        };
      }

      this.confirmDeleteDlg = false; // ซ้อนการแจ้งเตือน dialog
      this.getData();
    },
    copyTracking(tracking) {
      let linktrack = "";
       if (tracking.indexOf("PSR") == 0) {
        linktrack = `https://th.kerryexpress.com/th/track/v2/?track=${tracking}`;
      } else if (tracking.indexOf("TH") == 0) {
        linktrack = `https://www.flashexpress.co.th/tracking/?se=${tracking}`;
      } else if (tracking.indexOf("ALP") == 0) {
        linktrack = "http://www.alphafast.com/th/track?id=" + tracking;
      }

      this.$copyText(linktrack).then(
        function(e) {
          // alert('Copied')
          // console.log(e)
        },
        function(e) {
          // alert('Can not copy')
          console.log(e);
        }
      );
    },
    printOrder() {
      if (this.selected.length > 0) {
        this.$store.dispatch({
          type: "selectItems",
          items: this.selected,
        });
        this.$store.dispatch({
          type: "hideMenu",
          isHide: false,
        });
        this.$router.push(
          `/printorder/timestamp/` + Math.floor(Date.now() / 1000)
        );
      } else {
        console.log("no value");
      }
    },
    async exportFlash() {
      let itemsSelected = this.selected;
      if (itemsSelected.length > 0) {
        let ids = _.map(itemsSelected, item => {
          return item.id;
        });

        let formData = new FormData();
        formData.append("items", JSON.stringify(ids));

        this.dialogloading = true; // show dialog loading
        let result = await api.getOrdersById(formData);
        if (result.data.result == "ok") {
          let orders = JSON.parse(result.data.message);

          let mData = _.map(orders, order => {
               let order_id = ORDERID_PREFIX + _.padStart(order.id, "5", "0");
                let addrinfo2 = order.address_info2;
                let post_code = addrinfo2.substring(
                  addrinfo2.length - 5,
                  addrinfo2.length
                );
                let amount = "";
                if (order.is_cod == PAYMENTMETHOD.COD) {
                  if (order.amount > 0) {
                    amount = order.amount;
                  }
                }
                 //splitting district
                      let address_split = order.address_info2.split(", ");
                      let new_address = '';
                      if (address_split[2].trim() == 'กรุงเทพ') {
                        new_address = `แขวง ${address_split[0]} เขต ${address_split[1]} ${address_split[2]}`;                 
                      } else {
                        new_address = `ตำบล ${address_split[0]} อำเภอ ${address_split[1]} ${address_split[2]}`;
                      }

                return {
                  Customer_order_number: order_id,
                  Consignee_name: order.name,
                  Address: order.address_info + " " + new_address,
                  Postal_code: parseInt(post_code),
                  Phone_number: order.tel1,
                  Phone_number2: "",
                  COD: amount,
                  Item_type: "อื่นๆ",
                  Weight_kg: 1,
                  Length: 1,
                  Width: 1,
                  Height: 1,
                  Freight_insurance: "",
                  Value_insurance: "",
                  Declared_value: "",
                  Packaging_damage_insurance: "",
                  Product_type: "Standard",
                  remark1: "",
                  remark2: "",
                  remark3: ""
                }; 
            
          });

          let workbook = new Exceljs.Workbook();
          workbook.creator = "pols";
          let worksheet = workbook.addWorksheet("My Order");
          worksheet.columns = [
            {
              header: "Customer_order_number",
              key: "Customer_order_number",
              width: 12
            },
            { header: "*Consignee_name", key: "Consignee_name", width: 30 },
            { header: "*Address", key: "Address", width: 70 },
            { header: "*Postal_code", key: "Postal_code", width: 10 },
            { header: "*Phone_number", key: "Phone_number", width: 15 },
            { header: "Phone_number2", key: "Phone_number2", width: 15 },
            { header: "COD", key: "COD", width: 10 },
            { header: "Item_type", key: "Item_type", width: 10 },
            { header: "*Weight_kg", key: "Weight_kg", width: 10 },
            { header: "Length", key: "Length", width: 10 },
            { header: "Width", key: "Width", width: 10 },
            { header: "Height", key: "Height", width: 10 },
            { header: "Freight_insurance", key: "Freight_insurance", width: 10 },
            { header: "Value_insurance", key: "Value_insurance", width: 10 },
            { header: "Declared_value", key: "Declared_value", width: 10 },
            { header: "Packaging_damage_insurance", key: "Packaging_damage_insurance", width: 10 },
            { header: "*Product_type", key: "Product_type", width: 10 },
            { header: "Remark1", key: "remark1", width: 10 },
            { header: "Remark2", key: "remark2", width: 10 },
            { header: "Remark3", key: "remark3", width: 10 }
          ];

          let total_exported = 0;
          _.forEach(mData, r => {
            if (typeof r != 'undefined') {
                total_exported+=1;
                worksheet.addRow(r);
            }
          });

          try {
            let dname = this.$moment().format("DDMMYYYY-HHmmss");
            let filename = ORDERID_PREFIX + "-ORDER-"+ total_exported + "-" + dname.toString() + ".xlsx";
            let databuffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([databuffer]), filename);
            this.dialogloading = false;
          } catch (error) {
            console.log("export excel error:", error.message);
            this.dialogloading = false; // hide dialog loading
          }
          orders = null;
          mData = null;
          workbook = null;
          result = null; 
        } else {
          // no data
        }
        ids = null;
      } else {
        alert("กรุณาเลือก order ที่ต้องการ export!");
      }
      itemsSelected = null;
      this.selected = [];
    },
    doActionMenu(actionType) {
      this.selectedStatusAction = actionType;
      this.confirmChangeStatusDlg = true;
    },
    doChangeStatus() {
      this.changeOrderStatus();
      this.confirmChangeStatusDlg = false;
    },
    async changeOrderStatus() {
      if (this.selected.length > 0) {
        let itemsSelected = this.selected;
        let ids = _.map(itemsSelected, (item) => {
          return item.id;
        });

        let formData = new FormData();
        formData.append("items", JSON.stringify(ids));
        formData.append("status", this.selectedStatusAction.actionType);
        let result = await api.updateBulkOrderStatus(formData);
        if (result) {
          // update success
          this.snackbar = {
            active: true,
            msg: `อัพเดตสถานะสำเร็จ`,
          };

          this.selected = [];
          this.getData();
        } else {
          // update failed
          this.snackbarFaild = {
            active: true,
            msg: `อัพเดตสถานะไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`,
          };
        }
      }
    },
  },
  beforeDestroy: function() {
    delete this.mDataArray;
    delete this.selected;
    delete this.options;
  },
};
</script>

<style scoped>
.my-td-width {
  width: 120px;
}
.my-td-width-status {
  width: 130px;
}
.my-td-width3 {
  width: 200px;
}
.my-select-style2 {
  width: 120px;
  font-size: 14px;
  padding-top: 20px;
}
</style>
