<template>
  <v-container fluid grey lighten-3 pa-5>
    <!-- Summary section -->
    <v-snackbar
      v-model="snackbar.active"
      bottom
      color="yellow darken-2"
      :timeout="3000"
    >
      <v-icon light small class="mr-1">check_circle</v-icon>
      <b class="black--text">{{ snackbar.msg }}</b>
      <v-btn light text @click="snackbar.active = false">
        ปิด
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarFaild.active"
      bottom
      color="error"
      :timeout="3000"
    >
      <v-icon dark small class="mr-1">cancel</v-icon>
      <b>{{ snackbarFaild.msg }}</b>
      <v-btn dark text @click="snackbarFaild.active = false">
        ปิด
      </v-btn>
    </v-snackbar>

    <!-- Table section -->
    <v-row>
      <v-col>
        <v-btn
          @click="$router.push('/tag-create')"
          color="primary"
          dark
          class="mb-2"
          small
        >
          <v-icon left>add</v-icon>
          <span>New Tag</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-wrap">
        <v-card
          class="mr-1 mb-1"
          v-for="(item, index) in mDataArray"
          :key="index"
        >
          <v-card-text class="pb-1 text-center">
            <div class="caption">Id: {{ item.id }}</div>
            <p class="display-1 ma-1">
              <v-badge
                offset-x="20"
                offset-y="12"
                color="yellow accent-4"
                v-if="item.totalCount > 0"
              >
                <template v-slot:badge>
                  <span class="black--text">{{ item.totalCount }}</span>
                </template>
                <v-chip small @click="viewReportTag(item.id, item)">
                  {{ item.name }}
                </v-chip>
              </v-badge>
              <v-chip small v-else @click="viewReportTag(item.id, item)">
                {{ item.name }}
              </v-chip>
            </p>
            <div class="caption">
              สร้าง {{ item.createdAt | moment("DD/MM/YYYY") }}
            </div>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-icon size="18" class="mr-2" @click="editItem(item)">
              edit</v-icon
            >
            <v-icon size="18" class="mr-2" @click="deleteItem(item)">
              delete</v-icon
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12">
        <h2 class="mb-0">TAG REPORT</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <TagReportCard
          :tagId="selectTagId"
          :name="selectTagName"
          ref="TagReportCard"
          @reload_data="reloadData"
        ></TagReportCard>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDeleteDlg" max-width="290">
      <v-card>
        <v-card-title class="headline">Confirm Delete</v-card-title>
        <v-card-text class="body">
          ต้องการลบ <b>Tag</b> นี้ใช่หรือไม่?
          <h3>{{ selectedItem }}</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDeleteDlg = false"> cancel </v-btn>
          <v-btn color="error" text @click="confirmDelete">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

     <v-dialog v-model="dialogloading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please wait..
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/services/api";
import _ from "lodash";

import TagReportCard from "@/components/reports/TagReportCard";

export default {
  name: "TagReport",
  data() {
    return {
      selectTagId: "",
      selectTagName: "",
      mDataArray: [],
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "id",
          width: 80,
        },
        { text: "Name", value: "name" },
        { text: "Action", value: "action", width: 80 },
      ],
      search: "",
      selectedItemId: "",
      confirmDeleteDlg: false, // false เท่ากับไม่ให้แสดง
      selectedItem: "",
      dataTableLoading: true,
      options: {}, // for dataTable
      snackbar: {
        active: false,
        msg: "",
      },
      snackbarFaild: {
        active: false,
        msg: "",
      },
      dialogloading: false
    };
  },
  components: {
    // register ตัว components ที่เราทำการ import เข้ามาให้สามารถใช้งานได้
    TagReportCard,
  },
  mounted() {
    console.log("this tag components is running");
    this.loadData();
  },
  methods: {
    searchItems: _.debounce(function() {
      // _.debounce ใน lodash ต่างจาก settimeout อย่างไร
      // 1. debounce จะ return ค่าออกมาเป็น function  สว่น   settimeout จะ return เป็น id
      // 2. debounce ตัวอย่างการใช้เช่น เวลาพิมพ์ search ข้อมูล function ใน debounce จะทำงานก็ต่อเมื่อเราได้หยุดพิมพ์ในเวลา 1.5 วิ แต่ถ้าในเวลา 1.5 วิ มีการพิมพ์ต่อ debounce ก็จะนับถอยหลังใหม่
      //     ซึ่งจะทำให้ การยิง request ไปที่ server ลดจำนวนการยิงลงจนกว่าเราจะพิมพ์ข้อความที่ต้องการค้นหาเสร็จ และไม่มีการพิมพ์ต่อเนื่องใน 1.5 วินั้นเอง
      this.loadData();
    }, 1000),
    loadData() {
      this.getDataFromApi()
        .then((data) => {
          this.mDataArray = data.rows;
        })
        .catch((error) => {
          console.log("myerror :" + error);
        });
    },
    getDataFromApi() {
      this.dialogloading = true;
      return new Promise((resolve, reject) => {
        api.getTagReport().then((result) => {
            let rows = '';
          if (result.data.result == 'ok') {
              rows = JSON.parse(result.data.message);
          } else {
              rows = [];
          }
          setTimeout(() => {
            this.dialogloading = false;
            resolve({ rows });
            rows = [];
          }, 200);
        });
      });
    },
    editItem(item) {
      this.$router.push(`/tag-edit/${item.id}`);
    },
    deleteItem(item) {
      this.selectedItemId = item.id;
      this.selectedItem = `รหัส: ${item.id} ${item.name}`;
      this.confirmDeleteDlg = true;
    },
    async confirmDelete() {
      let result = await api.deleteTag(this.selectedItemId);
      if (result.data.result == "ok") {
        this.snackbar = {
          active: true,
          msg: "ลบข้อมูลสำเร็จ",
        };
        this.selectTagId = 0;
      } else {
        this.snackbarFaild = {
          active: true,
          msg: "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง",
        };
      }

      this.confirmDeleteDlg = false; // ซ้อนการแจ้งเตือน dialog
      this.loadData();
    },
    viewReportTag(id, name) {
      this.selectTagId = id;
      this.selectTagName = name;
    },
    reloadData(result) {
      if (result) {
        this.loadData();
      }
    },
  },
  watch: {},
  beforeDestroy: function() {
    delete this.mDataArray;
    delete this.options;
  }
};
</script>
